<template>
  <v-col class="px-0 py-0">
    <loader v-if="showLoader" />
    <v-col v-else class="px-0 py-0">
      <div class="backgroundUser">
        <v-row no-gutters align="center">
          <div
            style="
              width: 100px;
              height: 100px;
              border-radius: 50%;
              background: #4b5262;
              display: grid;
              place-items: center;
            "
          >
            <v-icon size="86px" color="#FAFAFA">mdi-account</v-icon>
          </div>
          <v-col cols="5" style="margin-left: 20px">
            <p style="font-size: 32px; font-weight: 600; margin-bottom: 4px">
              {{ userInfo.first_name }} {{ userInfo.last_name }}
            </p>
            <span style="color: #4b5262">
              {{ getUserRole(Object.values(userInfo.user_role)?.[0]) }}
            </span>
          </v-col>
          <v-row no-gutters align="center" justify="end">
            <v-col style="text-align: right; padding: 16px">
              <p
                style="
                  color: #144fa9;
                  font-family: 'MacPaw Fixel';
                  font-size: 40px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                  margin-bottom: 12px;
                "
              >
                <ICountUp
                  :delay="delay"
                  :endVal="statisticYearlyTotalSum"
                  :options="options"
                />
              </p>
              <span
                style="
                  color: #1b1b1b;
                  font-family: 'MacPaw Fixel';
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 700;
                  line-height: normal;
                "
                >Сума проданих квитків</span
              >
            </v-col>
          </v-row>
        </v-row>
      </div>
      <search-field
        style="margin-top: 20px"
        label="Пошук"
        placeholder="Введіть тут.."
        @search="setSearchField"
      />
      <div class="sectionBackground" style="padding: 20px">
        <p class="sectionTitle">Фільтр по даті</p>
        <div class="sectionLine" />
        <v-row no-gutters align="center">
          <v-menu
            offset-y
            bottom
            max-width="300px"
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-bind="attrs"
                v-on="on"
                outlined
                dense
                hide-details
                height="48px"
                style="border-radius: 10px"
                label="Період продажів"
                placeholder="За увесь час"
                color="#E2E2E2"
                v-model="dates"
              >
                <template v-slot:append-inner>
                  <img src="@/assets/img/iconsSvg/calendarIcon.svg" />
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              range
              no-title
              color="#144FA9"
              :min="minDate"
              :max="maxDate"
            ></v-date-picker>
          </v-menu>
          <cancel-btn
            text="Скинути фільтри"
            style="font-size: 16px; margin-left: 20px"
            @click="cancelFilters"
          />
          <submit-button
            style="width: 180px; height: 48px; margin-left: 20px"
            @click="applyFilters"
            >Застосувати</submit-button
          >
        </v-row>
        <p class="sectionTitle" style="margin-top: 40px">
          Показники диспетчера
        </p>
        <div class="sectionLine" />
        <v-row no-gutters>
          <v-col cols="6" style="padding-right: 10px">
            <statistic-card
              :numbers="statisticTotalTickets"
              title="Кількість проданих квитків"
              subtitle="за вище вказаний період"
            />
          </v-col>
          <v-col cols="6" style="padding-left: 10px">
            <statistic-card
              :numbers="statisticTotalSum"
              title="Сума проданих квитків"
              subtitle="за вище вказаний період"
            />
          </v-col>
        </v-row>
      </div>
      <orders-list :searchByAll="search" :dateSearch="dateSearch" />
    </v-col>
  </v-col>
</template>

<script>
import statisticService from "../../../../requests/Admin/statisticService";
import usersService from "../../../../requests/Admin/usersService";
import cancelBtn from "../../../UI/Buttons/cancelBtn.vue";
import SubmitButton from "../../../UI/Buttons/submitButton.vue";
import StatisticCard from "../../../UI/Cards/statisticCard.vue";
import SearchField from "../../../UI/Fields/searchField.vue";
import Loader from "../../../UI/Loader.vue";
import OrdersList from "./ordersList.vue";
import ICountUp from "vue-countup-v2";
export default {
  components: {
    cancelBtn,
    SubmitButton,
    StatisticCard,
    SearchField,
    OrdersList,
    Loader,
    ICountUp
  },
  data: () => ({
    search: "",
    dates: ["", ""],
    dateSearch: "",
    statisticYearlyTotalSum: "",
    statisticTotalSum: "",
    statisticTotalTickets: "",
    showLoader: true,
    userInfo: {},
    delay: 500,
    options: {
      useEasing: true,
      useGrouping: true,
      separator: "",
      decimal: ".",
      prefix: "",
      suffix: "",
    },
  }),
  mounted() {
    this.getUserInfo();
    this.getStatistic();
  },
  methods: {
    async getUserInfo() {
      await usersService.getUser(this.$route.params.id).then((res) => {
        if (res.status == "success") {
          this.userInfo = res.data;
        }
      });
    },
    getUserRole(role) {
      switch (role) {
        case "admin":
          return "Адміністратор";
        case "manager":
          return "Менеджер";
        case "dispatcher":
          return "Диспетчер";
        case "moderator":
          return "Модаратор";
        case "guest":
          return "Гість";
      }
      return "Адміністратор";
    },
    setSearchField(search) {
      if (search !== undefined && search !== null) {
        this.search = search;
      } else {
        this.search = "";
      }
    },
    applyFilters() {
      if (
        this.dates.length > 1 &&
        this.dates[0] !== "" &&
        this.dates[1] !== ""
      ) {
        this.dateSearch = `paid_at[between]:${this.dates?.[0]} 00:00:00.${this.dates?.[1]} 23:59:59`;
      } else if (this.dates.length > 0 && this.dates[0] !== "") {
        this.dateSearch = `paid_at[between]:${this.dates?.[0]} 00:00:00.${this.dates?.[0]} 23:59:59`;
      } else {
        this.dateSearch = "";
      }
      this.getStatistic();
    },
    cancelFilters() {
      this.dates = ["", ""];
      this.dateSearch = "";
      this.getStatistic();
    },
    async getStatistic() {
      const promises = [
        await statisticService
          .getManagerStatistic(
            "Yearly",
            "PricePayed",
            "",
            "",
            this.$route.params.id
          )
          .then((res) => {
            if (res.status == "Success") {
              this.statisticYearlyTotalSum = res.data?.[0]?.total;
            }
          }),
        await statisticService
          .getManagerStatistic(
            "Yearly",
            "PricePayed",
            `${
              this.dates?.[0] !== undefined && this.dates?.[0] !== ""
                ? `${this.dates?.[0]} 00:00:00`
                : ""
            } `,
            `${
              this.dates.length > 1 && this.dates?.[1] !== ""
                ? `${this.dates?.[1]} 23:59:59`
                : this.dates.length == 1
                ? `${this.dates?.[0]} 23:59:59`
                : ""
            }`,
            this.$route.params.id
          )
          .then((res) => {
            if (res.status == "Success") {
              this.statisticTotalSum = res.data?.[0]?.total;
            }
          }),
        await statisticService
          .getManagerStatistic(
            "Yearly",
            "TicketPayed",
            `${
              this.dates?.[0] !== undefined && this.dates?.[0] !== ""
                ? `${this.dates?.[0]} 00:00:00`
                : ""
            } `,
            `${
              this.dates.length > 1 && this.dates?.[1] !== ""
                ? `${this.dates?.[1]} 23:59:59`
                : this.dates.length == 1
                ? `${this.dates?.[0]} 23:59:59`
                : ""
            }`,
            this.$route.params.id
          )
          .then((res) => {
            if (res.status == "Success") {
              this.statisticTotalTickets = res.data?.[0]?.total;
            }
          }),
      ];
      Promise.all(promises).then(() => {
        this.showLoader = false;
      });
    },
  },
  computed: {
    minDate() {
      if (this.dates?.[0] && this.dates?.[0] !== "") {
        return new Date(this.dates?.[0]).toISOString().substring(0, 10);
      }
      return new Date(null).toISOString().substring(0, 10);
    },
    maxDate() {
      return new Date().toISOString().substring(0, 10);
    },
  },
};
</script>

<style scoped>
.backgroundUser {
  margin-top: 20px;
  padding: 20px;
  background: url("../../../../assets/img/userBackground.png") lightgray 50% /
    cover no-repeat;
  background-size: cover;
  height: 200px;
  width: 100%;
  border-radius: 10px;
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  align-items: center;
}
</style>